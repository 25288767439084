import "./links.css"
import LinkCard from "./linkCard"
import sampleImage from "./../../components/images/profilepic2.png"
import { useEffect, useState } from "react"
import { faInstagram, faTwitterSquare, faTwitch, faTiktok, faYoutube} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faLock} from "@fortawesome/free-solid-svg-icons"
import RRLogoNoBK from "./../../components/images/RRLogoNoBK.png"
import OF from "./../../components/images/of.png"
import Twitter from "./../../components/images/twitter2.png"
import YouTube from "./../../components/images/youtube.png"
import Snap from "./../../components/images/snap.png"
import Insta from "./../../components/images/Insta2.png"
import TikTok from "./../../components/images/tiktok.png"
import OFPic from "./../../components/images/OFPic.png"
import TwitterPic from "./../../components/images/twitterPic.png"
import SnapPic from "./../../components/images/snapPic.png"
import InstaPic from "./../../components/images/InstaPic.png"
import TikTokPic from "./../../components/images/tiktokPic.png"

const LinksPage = () => {

    const [showAgeVerify, SetShowAgeVerify] = useState("none")


    function instaRoute(){
        window.open(
            'https://www.instagram.com/raerockhold/?hl=en',
            '_blank'
        );
    }

    function youTubeRoute(){
        window.open(
            'https://www.youtube.com/channel/UCtLSmCk9IqOyYhg3uzHO6iQ/videos',
            '_blank'
        );
    }

    function tikTokRoute(){
        window.open(
            'https://www.tiktok.com/@raerockhold',
            '_blank'
        );
    }

    function twitterRoute(){
        window.open(
            'https://twitter.com/RaeRockhold?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            '_blank'
        );
    }

    function snapRoute(){
        window.open(
            'https://www.snapchat.com/add/raerockhold',
            '_blank'
        );
    }

    function OFRoute(){
        acceptedAgeWarning()
    }    

    function acceptedAgeWarning(){
        SetShowAgeVerify("block")
    }

    function closeWindow(){
        SetShowAgeVerify("none")
    }

    function continueLink(){
        SetShowAgeVerify("none")
        window.open(
            'https://onlyfans.com/raerockhold',
            '_blank'
        );   
    }

    return(
        <div>
            <div className="body-container">
                <div className="main-container">
                    <div className="inner-container">    
                        <div className="logo">
                            <img src={RRLogoNoBK}/>
                        </div>
                        <div className="ageVerify" style={{display:showAgeVerify}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindow}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLink}>Continue</button>
                            </div>
                        </div>                    
                        <div className="top-section">
                            <div className="profile-picture-outline">
                                <div className="profile-picture">
                                    <img src={sampleImage}/>
                                </div>
                            </div>
                            <div className="Name">
                                <h1>Rae Rockhold</h1>                            
                            </div>
                            <div className="bio">
                                <p>Welcome to all things Rae &#9825;</p>                            
                            </div>
                            <div className="socials">
                                <div className="social-container2">
                                    <img src={OF} onClick={OFRoute}/>
                                    {/* <FontAwesomeIcon icon={faLock} className="social-icon" onClick={OFRoute}/> */}
                                </div>
                                <div className="social-container2">
                                    <img src={Insta}  onClick={instaRoute}/>
                                    {/* <FontAwesomeIcon icon={faInstagram} className="social-icon" onClick={instaRoute}/> */}
                                </div>
                                <div className="social-container2">
                                    <img src={Twitter} onClick={twitterRoute}/>
                                    {/* <FontAwesomeIcon icon={faTwitterSquare} className="social-icon-twitter" onClick={twitterRoute}/> */}
                                </div>
                                <div className="social-container-tiktok">
                                    <img src={TikTok} onClick={tikTokRoute}/>
                                    {/* <FontAwesomeIcon icon={faTiktok} className="social-icon" onClick={tikTokRoute}/> */}
                                </div>
                                <div className="social-container2">
                                    <img src={Snap} onClick={snapRoute}/>
                                    {/* <FontAwesomeIcon icon={faYoutube} className="social-icon" onClick={youTubeRoute}/> */}
                                </div>
                            </div> 
                        </div>
                        <div className="picture-link-section">
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/OFPic2.png)"}}
                                    onClick={OFRoute}>
                                    <h2>OnlyFans</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/InstaPic.png)"}}
                                    onClick={instaRoute}>
                                    <h2>Instagram</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/twitterPic.png)"}}
                                    onClick={twitterRoute}>
                                    <h2>Twitter</h2>
                                </div>
                            </div>
                            {/* <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg)"}}
                                    onClick={tikTokRoute}>
                                    <h2>TikTok</h2>
                                </div>
                            </div> */}
                            <div className="smaller-card-container">
                                <div className="card-body-small">
                                    <div className="card-inner-body" 
                                        style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/tiktokPic.png)"}}
                                        onClick={tikTokRoute}>
                                        <h2>TikTok</h2>
                                    </div>
                                </div>
                                <div className="card-body-small">
                                    <div className="card-inner-body" 
                                        style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/snapPic.png)"}}
                                        onClick={snapRoute}>
                                        <h2>SnapChat</h2>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <div className="footer-section">
                            {/* <h3>&#10084; All the links you want are above &#10084;</h3> */}
                        </div>
                    </div>
                </div>    
            </div>
            <div className="body-container-mobile">
                <div className="main-container-mobile">
                    <div className="inner-container">    
                        <div className="logo">
                            <img src={RRLogoNoBK}/>
                        </div>
                        <div className="ageVerify" style={{display:showAgeVerify}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindow}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLink}>Continue</button>
                            </div>
                        </div>   
                        <div className="top-section">
                            <div className="profile-picture-outline">
                                <div className="profile-picture">
                                    <img src={sampleImage}/>
                                </div>
                            </div>
                            <div className="Name">
                                <h1>Rae Rockhold</h1>                            
                            </div>
                            <div className="bio">
                                <p>Welcome to all things Rae &#9825;</p>                            
                            </div>
                            <div className="socials">
                                <div className="social-container">
                                    <img src={OF} onClick={OFRoute}/>
                                    {/* <FontAwesomeIcon icon={faLock} className="social-icon" onClick={OFRoute}/> */}
                                </div>
                                <div className="social-container">
                                    <img src={Insta} onClick={instaRoute}/>
                                    {/* <FontAwesomeIcon icon={faInstagram} className="social-icon" onClick={instaRoute}/> */}
                                </div>
                                <div className="social-container">
                                    <img src={Twitter} onClick={twitterRoute}/>
                                    {/* <FontAwesomeIcon icon={faTwitterSquare} className="social-icon-twitter" onClick={twitterRoute}/> */}
                                </div>
                                <div className="social-container-tiktok">
                                    <img src={TikTok} onClick={tikTokRoute}/>
                                    {/* <FontAwesomeIcon icon={faTiktok} className="social-icon" onClick={tikTokRoute}/> */}
                                </div>
                                <div className="social-container2">
                                    <img src={Snap} onClick={snapRoute}/>
                                    {/* <FontAwesomeIcon icon={faYoutube} className="social-icon" onClick={youTubeRoute}/> */}
                                </div>
                            </div> 
                        </div>
                        <div className="picture-link-section">
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/OFPic2.png)"}}
                                    onClick={OFRoute}>
                                    <h2>OnlyFans</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/InstaPic.png)"}}
                                    onClick={instaRoute}>
                                    <h2>Instagram</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/twitterPic.png)"}}
                                    onClick={twitterRoute}>
                                    <h2>Twitter</h2>
                                </div>
                            </div>
                            <div className="smaller-card-container-mobile">
                                <div className="card-body-small">
                                    <div className="card-inner-body" 
                                        style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/tiktokPic.png)"}}
                                        onClick={tikTokRoute}>
                                        <h2>TikTok</h2>
                                    </div>
                                </div>
                                <div className="card-body-small">
                                    <div className="card-inner-body" 
                                        style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/snapPic.png)"}}
                                        onClick={snapRoute}>
                                        <h2>SnapChat</h2>
                                    </div>
                                </div>                                
                            </div>

                        </div>
                        <div className="footer-section">
                            {/* <h3>&#9825; All the links you want are above &#9825;</h3> */}
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    )
}

export default LinksPage